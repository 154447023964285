<template>
  <div class="toplist" id="toplist">
  <div class="wrapper">
    <swiper-container class="the-swiper"
    :slides-per-view="1"
    :space-between="spaceBetween"
    :centered-slides="false"
    :navigation="true"
    :autoplay="true"
    :speed="1000"
    :breakpoints="{
      600: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      }
    }"
    @swiperprogress="onProgress"
    @swiperslidechange="onSlideChange"
  >
  <template v-for="item in items" :key="item['Nombre']">
    <swiper-slide  v-if="item.activo"  lazy="true">
  <div class="review">
    <div class="review__inner">
    <div class="review__image-holder">
      <img :src="item.logo" :alt="`${item.url_resena}-${item.Nombre}-logo`" class="img review__image" loading="lazy">
    </div>
  <div class="review__btn-holder">
  <div v-if="item.url"><a :href="item.url" class="review__btn btn btn--primary">
  <span class="btn-text_">Jugar en el Casino</span><!--Al casino -->
  </a></div>  
<div><a :href="item.url_resena" class="res-button btn-text_">
  Leer reseña <!-- A reseñas -->
  </a></div>
  </div>
  </div>
  </div>
</swiper-slide>
</template>
</swiper-container>
  </div>
  </div>
</template>

<script>
import { register } from 'swiper/element/bundle';
import { Autoplay, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
register({
  modules: [Navigation, Autoplay]
});
import { onlineCasinos } from  './casinos'
export default {
  name: 'App',
  setup() {
    const casinos = onlineCasinos()
    const spaceBetween = 32;
    const onProgress = (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress),
        console.log(swiper)
    };

    const onSlideChange = (e) => {
      console.log('Cambio de diapo')
      console.log(e)
    }

    return {
      spaceBetween,
      onProgress,
      onSlideChange,
      ...casinos
    };
  }
}
</script>
<style lang="css" scoped>
.h-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

</style>
